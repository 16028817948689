import "./detail.scss";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { serverAddress } from "../../App";
import { responseCode } from "../../config";
import { useNavigate } from "react-router-dom";
import ArrowIcon from "../../components/arrowIcon/arrowIcon";
import defaultAvatar from '../../assets/avatar.svg'
import copy from 'copy-to-clipboard';
import { bottomToast } from "../../utils/toast";
import video from '../../assets/nft/dma-video.mp4';
import poster from '../../assets/nft/dma-nft-image.jpg';
import BackIcon from "../../assets/by-health/backIcon.png";
import TitleIcon from "../../assets/by-health/titleIcon.png";
import UnmuteIcon from "../../assets/by-health/unmute.png";
import MuteIcon from "../../assets/by-health/mute.png";
import Background from '../../assets/by-health/background.png';
import OkButton from "../../components/button/button";
import LeftIcon from "../congratsPage/assets/left-icon.png";
import RightIcon from "../congratsPage/assets/right-icon.png";
import SmallRectangle from "../congratsPage/assets/small-rectangle.png";
import Frame from './assets/frame.png';
import {stopBodyScroll} from "../../components/progressLoading/ProgressLoading";
import {getNumber} from "../congratsPage/Congrats";
import goldYin from '../../assets/by-health/gif/goldYin.gif';
import sliverYin from '../../assets/by-health/gif/sliverYin.gif';
import normalProtein from '../../assets/by-health/gif/normalProtein.gif';
import goldProtein from '../../assets/by-health/gif/goldProtein.gif';

export default function Detail(props) {
    const { theme, style, mute, setMute } = props;

    const navigate = useNavigate();
    const { id } = useParams();

    const [nftInfo, setInfo] = useState({});

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 15, 15);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const goToPoster = () => {
        navigate("/poster/" + id);
        window.scrollTo(0,0)
    }

    const copyAddress = () => {
        copy(nftInfo.address)
        bottomToast('复制成功');
    }

    useEffect(() => {
        try {
            fetch(serverAddress + "/nft/" + id, {
                method: "GET",
            }).then((r) => {
                r.json().then((result) => {
                    if (result.returnCode === responseCode.success) {
                        const { ownedAt, name, ownerUserInfo, displayURL, collectionInfo,creator, tokenId } = result.data;
                        setInfo({ avatar: defaultAvatar, author: collectionInfo.publisher,ownedAt, id, name, ownerUserInfo, image:displayURL, publisher: collectionInfo.publisher, address: collectionInfo.contractAddress, creator: creator, tokenId });

                        // const video = document.getElementById('videoPlay');
                        // video.play();
                        // video.muted = false;
                       }
                });
            });
        } catch (error) {
            console.log("error", error);
        }
    }, [id]);

    useEffect(()=>{
        window.scrollTo(0,0);
    },[])

    return (
        <div className={`detail-page ${theme==='dark'?'detail-page-dark-theme':''}`} style={{backgroundImage: `url(${Background})`}}>
            <img src={BackIcon} alt='' style={{height: '14px',width:'9px', position: 'absolute', top: '22px', left: '21px'}} onClick={()=> navigate(-1)}/>
            <img src={TitleIcon} alt='' style={{width: '164px', marginTop: '20px'}}/>
            <img src={mute ? UnmuteIcon : MuteIcon} alt='' style={{height: '27px', position: 'absolute', top: '15px', right: '15px'}}  onClick={() => setMute(!mute)}/>
            {/*<div className={`detail-nft-title ${theme==='dark'?'detail-nft-title-dark-theme':''}`} id={'nftName'}>{nftInfo.name}</div>*/}
            {/*<div className={`detail-nft-id ${theme==='dark'?'detail-nft-id-dark-theme':''}`}># {nftInfo.tokenId}</div>*/}
            {/*<div className={`top-nft-info-container ${theme==='dark'?'top-nft-info-container-dark-theme':''}`}>*/}
            {/*    <div className='inside-nft-video'>*/}
            {/*    <video id='videoPlay' width="100%" height='100%'  style={{borderRadius: '12px 12px 0 0 '}} muted playsInline controls loop>*/}
            {/*        <source src={video} type="video/mp4"/>*/}
            {/*    </video>*/}
            {/*    </div>*/}

            {/*    /!*<img className="inside-nft-img" src={nftInfo.image + "!small"} alt=""/>*!/*/}
            {/*   */}
            {/*    <div className="nft-card-bottom-container">*/}
            {/*        <img className="detail-nft-avatar" src={nftInfo.avatar} alt=""/>*/}
            {/*        <div className={`detail-nft-author-name ${theme==='dark'?'detail-nft-author-name-dark-theme':''}`}>{nftInfo.author}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*<div className={`bottom-nft-info-container ${theme==='dark'?'bottom-nft-info-container-dark-theme':''}`}>*/}
            {/*    <div className={`bottom-info-title ${theme==='dark'?'bottom-info-title-dark-theme':''}`}>藏品信息</div>*/}
            {/*    <div className="bottom-detail-into-container">*/}
            {/*        <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>藏品名称</div>*/}
            {/*        <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.name}</div>*/}
            {/*    </div>*/}
            {/*    <div className="bottom-detail-into-container">*/}
            {/*        <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>合约地址</div>*/}
            {/*        <div className={`detail-info-content address-info-container ${theme==='dark'?'detail-info-content-dark-theme':''}`} style={{color: style.addressColor}} onClick={copyAddress}>*/}
            {/*            <div className="address-copy-button"/>*/}
            {/*            <div>{nftInfo.address?getSubStr(nftInfo.address):''}</div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*    <div className="bottom-detail-into-container">*/}
            {/*        <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>发行方</div>*/}
            {/*        <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.publisher?getSubStr(nftInfo.publisher):''}</div>*/}
            {/*    </div>*/}
            {/*    <div className="bottom-detail-into-container">*/}
            {/*        <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>创作作者</div>*/}
            {/*        <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.creator?getSubStr(nftInfo.creator):''}</div>*/}
            {/*    </div>*/}
            {/*    <div className="bottom-detail-into-container">*/}
            {/*        <div className={`detail-info-title ${theme==='dark'?'detail-info-title-dark-theme':''}`}>创建时间</div>*/}
            {/*        <div className={`detail-info-content ${theme==='dark'?'detail-info-content-dark-theme':''}`}>{nftInfo.ownedAt?nftInfo.ownedAt.toString().substr(0,10):''}</div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            <div className='details-page-title-container'>
                <img src={LeftIcon} alt='' style={{width: '27px', marginRight: '10px'}}/>
                <div style={{minWidth: '170px'}}>{nftInfo.name}</div>
                <img src={RightIcon} alt='' style={{width: '27px', marginLeft: '10px'}}/>
            </div>
            <div style={{ position: 'relative'}}>
                <img src={SmallRectangle} alt='' style={{width: '132px', height: '24px', marginTop: '14px'}}/>
                <div className='details-page-subtitle-container'>
                    #{nftInfo?.tokenId}/3000
                </div>
            </div>
            <div className='details-page-frame-container'>
                <img src={Frame} alt='' style={{width: '100%'}}/>
                <div className='details-page-frame-container-inline-img-container'>
                    {/*<video id='videoPlay' width='100%'  muted playsInline controls loop>*/}
                    {/*    <source type="video/mp4" src={video}/>*/}
                    {/*</video>*/}
                    <img  src={getGif(nftInfo?.name)} style={{width: '100%'}}/>
                </div>
                <div className='details-page-frame-container-info-container'>
                    <div style={{fontFamily:'FZLanTingHeiS-B-GB', marginBottom: '12px'}}>数字藏品信息</div>
                    <div className='details-page-frame-container-info-container-line-container'>
                        <div>发行方</div>
                        <div>{nftInfo.publisher?getSubStr(nftInfo.publisher):''}</div>
                    </div>
                    <div className='details-page-frame-container-info-container-line-container'>
                        <div>创建时间</div>
                        <div>{nftInfo.ownedAt?nftInfo.ownedAt.toString().substr(0,10):''}</div>
                    </div>
                    <div className='details-page-frame-container-info-container-line-container'>
                        <div>创作作者</div>
                        <div>{nftInfo.creator?getSubStr(nftInfo.creator):''}</div>
                    </div>
                    <div className='details-page-frame-container-info-container-line-container'>
                        <div>区块链唯一编号</div>
                        <div>{nftInfo.address?getSubStr(nftInfo.address):''}</div>
                    </div>
                </div>
            </div>
            <div className={`detail-page-footer-warning ${theme==='dark'?'detail-page-footer-warning-dark-theme':''}`}>
                本网站仅代为展示和提取数字藏品创意作品，严格禁止自然人、法人或非法人组织利用数字藏品创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。
            </div>
            <div className='detail-info-content-footer'>
                <OkButton Click={goToPoster} text='生成海报'/>
            </div>

        </div>
    );
}

function getGif(name) {
    switch (name) {
        case '一诺千金「诚信印」常规款':
            return sliverYin;
        case '一诺千金「诚信印」足金款':
            return goldYin;
        case '「经典双蛋白」常规款':
            return normalProtein;
        case '「经典双蛋白」足金款':
            return goldProtein;
        default:
            return  undefined;
    }
}
