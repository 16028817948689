import {AuthContext, portalAddress} from "../App";
import {useContext} from "react";
import {bottomToast} from "./toast";

function usePostAuthentication() {
    const { state } = useContext(AuthContext);

    return async (code, name, idCard) => {
        let headers = new Headers();
        headers.append("accessToken", state.accessToken);
        try {
            const response = await fetch(
                portalAddress + "/user/authentication",
                {method: "POST", headers: headers, body: JSON.stringify({code: code, name: name, IDCard: idCard}),}
            );
            const json = await response.json();
            if (json.returnCode === "20000") {
                return json.data;
            } else {
                switch (json.returnCode) {
                    case '23003':
                        bottomToast("信息不匹配！");
                        break;
                    case '23004':
                        bottomToast("手机号码无效！");
                        break;
                    case '23005':
                        bottomToast("身份证号码有误！");
                        break;
                    case '23006':
                        bottomToast("没有记录！");
                        break;
                    default:
                        bottomToast("认证失败！");
                        break;
                }
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    };
}

function useGetAuthentication(accessToken) {
    return async () => {
        let headers = new Headers();
        headers.append("accessToken", accessToken);
        try {
            const response = await fetch(
                portalAddress + "/user/authentication",
                {method: "GET", headers: headers}
            );
            const json = await response.json();
            if (json.returnCode === "20000") {
                return json.data;
            } else {
                bottomToast({
                    content: json.msg
                })
                return false;
            }
        } catch (error) {
            console.log("error", error);
            return false;
        }
    };
}

export {usePostAuthentication, useGetAuthentication};