import Congrats from "./Congrats";
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function CongratsConfigsPage(props){
    const { mute, setMute } = props;
    const { theme } = useContext(ThemeCtx);
    const style = {
        buttonBackground: `linear-gradient(180deg, ${theme.color} 0%, ${theme.color}B3 100%)`
    }

    return(
        <Congrats style={style} theme={theme.main} mute={mute} setMute={setMute}/>
    )
}


// const style = {
//     button: {
//         buttonBackgroundColor: "#281EC8",
//         buttonContentColor: "#ffffff",
//     },
//     titleStyle: {
//         color: "#ffffff",
//         fontSize: "15px",
//         paddingLeft: 10,
//         paddingRight: 10,
//         paddingBottom: 5,
//         marginTop: '61px'
//     },
//     background: {
//       marginTop: '81px'
//     },
//     frame:{
//         height: '356px',
//         width: '293px',
//         backgroundImage: CardFrame,
//         contentImage: nft,
//         content:{
//             height: '266px',
//             width: '266px'
//         }
//     }
// };