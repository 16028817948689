import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router";
import {AuthContext, serverAddress} from "../../App";
import ProgressLoading, {stopBodyScroll} from '../../components/progressLoading/ProgressLoading';
import ArrowIcon from "../../components/arrowIcon/arrowIcon";
import "./poster.scss";
import * as htmlToImage from "html-to-image";
import QrCode from '../../assets/by-health/qrcode.png';
import {bottomToast} from "../../utils/toast";
import DownloadIcon from './assets/downloadIcon.png'
import lightDownloadIcon from './assets/download-icon-light.png'
import BackIcon from "../../assets/by-health/backIcon.png";
import Background from '../../assets/by-health/poster-background.png';
import LeftIcon from "../congratsPage/assets/left-icon.png";
import RightIcon from "../congratsPage/assets/right-icon.png";
import SmallRectangle from "../congratsPage/assets/small-rectangle.png";
import Frame from './assets/frame.png';

export default function Poster(props){
    const{ theme } =props;

    const { state } = useContext(AuthContext);
    const stageRef = useRef();

    const navigate = useNavigate();
    const [nft, setNft] = useState();
    const [loaded, setLoaded] = useState(false);
    const [nftLoaded, setNftLoaded] = useState(false);

    const [tokenId, setTokenId] = useState();
    const [owner, setOwner] = useState();
    const [name, setName] = useState();
    const [publisher, setPublisher] = useState();
    const [creator, setCreator] = useState();
    const [address, setAddress] = useState();
    const [total, setTotal] = useState();
    const [backgroundLoad, setBackgroundLoad] = useState(false);
    const params = useParams();

    const displayPoster = (url) => {
        const parentDiv = document.getElementById('stageDiv');
        parentDiv.style.backgroundImage= '';
        parentDiv.style.height='';
        parentDiv.innerHTML ='';
        const result = document.createElement("img");
        result.style.width = '100%';
        result.style.position = 'absolute';
        result.style.left = '0';
        result.src = url;
        stageRef.current.appendChild(result);
        stageRef.current.scrollTo(0,0);
        result.onload = () => {
            setLoaded(true);
        };
        // setLoaded(true);
    }

    const getSubStr = (str) => {
        if(str&&str.length > 25){
            let subStr1 = str.substr(0, 7);
            let subStr2 = str.substr(str.length - 15, 15);
            return subStr1 + "..." + subStr2;
        }
       return str;
    };

    const setInformation=(res)=>{
        setTokenId(res.data.tokenId);
        setOwner(res.data.ownedAt);
        setName(res.data.name);
        setPublisher(res.data.collectionInfo.publisher);
        setCreator(res.data.creator)
        setAddress(res.data.collectionInfo.contractAddress);
        setTotal(res.data.collectionInfo.amount)
    }

    const errorToast =() => {
        bottomToast('网络波动，请重试', 'center',()=>{
            navigate(`/detail/${params.id}`)
        })
    }

    useEffect(() => {
            if (state.isLogin) {
                fetch(serverAddress + "/nft/"+ params.id, { method: "GET" })
                    .then((res) => {
                        if (res.status === 200) {
                            res.json().then((res) => {
                                if (res.returnCode === "20000") {
                                    let url = res.data.thumbnailURL;
                                    let nftImg = document.createElement("img");
                                    nftImg.crossOrigin = "anonymous";
                                    nftImg.src = url;
                                    nftImg.onload = function(){
                                        setNftLoaded(true);
                                        setNft(nftImg.src+"!small");
                                    }
                                    setInformation(res)
                                }
                            }).catch(()=>errorToast());
                        }
                    });
            }
        }, [state.isLogin]);

    useEffect(() => {
        if (nftLoaded && backgroundLoad) {
            setTimeout(() => {
                fetch(serverAddress + "/poster/isUploaded?nftId=" + params.id, {
                    method: "GET",
                    headers: {"accessToken": state.accessToken}
                }).then((res) => {
                    if (res.status === 200) {
                        res.json().then(r => {
                            if (r.returnCode === "20000") {
                                if (r.data.length === 0) {
                                    htmlToImage.toBlob(stageRef.current).then(() => {
                                        htmlToImage.toBlob(stageRef.current).then(() => {
                                            htmlToImage.toBlob(stageRef.current).then(() => {
                                                htmlToImage.toBlob(stageRef.current).then((blob) => {
                                                    let img = new File([blob], 'poster.png');
                                                    let formData = new FormData();
                                                    formData.append('file', img);
                                                    fetch(serverAddress + "/poster/uploadOss?nftId=" + params.id, {
                                                        method: "POST",
                                                        headers: {"accessToken": state.accessToken},
                                                        body: formData
                                                    }).then((res) => {
                                                        if (res.status === 200) {
                                                            res.json().then(r => {
                                                                if (r.returnCode === "20000") {
                                                                    displayPoster(r.data);
                                                                }})}}).catch(() => errorToast())
                                                });});})})
                                } else {
                                    displayPoster(r.data);
                                }}
                        })}
                }).catch(() => errorToast())
            }, 500);}
    }, [nftLoaded]);

    useEffect(() => {
        if(!loaded){
            document.body.style.overflow='hidden';
        }else{
            document.body.style.overflow='';
        }
    },[loaded])

    useEffect(() => {
        if(!localStorage.getItem('accessToken')){
            navigate('/')
        }
    },[])

    return(
        <div className="poster-container">
            <ProgressLoading text={'海报生成中...'} isShowing={!loaded}/>
            <img src={BackIcon} alt='' style={{height: '14px',width:'9px', position: 'absolute', top: '33px', left: '21px', zIndex:'999'}} onClick={()=> {
                navigate(-1);
                window.scrollTo(0, 0);
            }}/>
            <img className="download-icon" onClick={()=>bottomToast('长按图片下载海报')} src={DownloadIcon} alt=''/>
            <div ref={stageRef} id='stageDiv' className={`stageDiv ${theme==='dark'?'stageDiv-dark-theme':''}`} style={{backgroundImage: `url(${Background})`}}>
                {/*<div className={`nft-poster-title ${theme==='dark'?'nft-poster-title-dark-theme':''}`}>{name}</div>*/}
                <div className='poster-page-title-container'>
                    <img src={LeftIcon} alt='' style={{width: '27px', marginRight: '10px'}}/>
                    <div style={{minWidth: '170px'}}>{name}</div>
                    <img src={RightIcon} alt='' style={{width: '27px', marginLeft: '10px'}}/>
                </div>
                <div style={{ position: 'relative'}}>
                    <img src={SmallRectangle} alt='' style={{width: '132px', height: '24px', marginTop: '12px'}}/>
                    <div className='poster-page-subtitle-container'>
                        {`#${tokenId}/${total}`}
                    </div>
                </div>
                <div className='poster-page-frame-container'>
                    <img src={Frame} alt='' style={{width:'100%'}} onLoad={()=> setBackgroundLoad(true)}/>
                    <div className='poster-page-frame-container-inline-frame'>
                        <img src={nft} alt="" style={{width: '100%'}}/>
                    </div>
                </div>
                {/*<div className={`nft-poster-subtitle ${theme==='dark'?'nft-poster-subtitle-dark-theme':''}`}>{`#${tokenId}/${total}`}</div>*/}
                {/*<div className='nft-poster-image-container'>*/}
                {/*    <img src={nft} alt=""/>*/}
                {/*</div>*/}
                <div className={`poster-bottom-info-container ${theme==='dark'?'poster-bottom-info-container-dark-theme':''}`}>
                    <div className={`bottom-info-title ${theme==='dark'?'bottom-info-title-dark-theme':''}`}>数字藏品信息</div>
                    <div className="bottom-info-sub-container" style={{marginTop:'10px'}}>
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>发行方</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{getSubStr(publisher)}</div>
                    </div>
                    <div className="bottom-info-sub-container">
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>创建时间</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{owner?owner.toString().substr(0,10):''}</div>
                    </div>
                    <div className="bottom-info-sub-container">
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>创作作者</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{getSubStr(creator)}</div>
                    </div>
                    <div className="bottom-info-sub-container">
                        <div className={`sub-container-title ${theme==='dark'?'sub-container-title-dark-theme':''}`}>合约地址</div>
                        <div className={`sub-container-content ${theme==='dark'?'sub-container-content-dark-theme':''}`}>{getSubStr(address)}</div>
                    </div>
                </div>
                <div className="poster-bottom-qrcode-container">
                    <div className="qrcode-container">
                        <div className={`qrcode-info ${theme==='dark'?'qrcode-info-dark-theme':''}`}>
                            <div className="qrcode-text" style={{width: '140px', textAlign: 'right'}}>扫描二维码</div>
                            <div className="qrcode-text" style={{width: '140px', textAlign: 'right'}}>了解更多信息</div>
                        </div>
                        <img className="bottom-left-qrcode" src={QrCode} alt=""/>
                    </div>
                    {/*<div className="logo-group"/>*/}
                </div>
            </div>
        </div>
    );
}
