import CertificationPage from "./CertificationPage";
import {useContext} from "react";
import {ThemeCtx} from "../../App";

export default function Certification() {
  const { theme } = useContext(ThemeCtx);

  const styleConfig = {
    buttonBackground: `rgba(95, 156, 218, 0.5)`
  }

  return(
      <CertificationPage theme={theme} style={styleConfig}/>
  )
}
