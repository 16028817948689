import './ProgressLoading.scss';
import Background from '../../assets/by-health/background.png';
import TitleIcon from '../../assets/by-health/titleIcon.png';
import {useEffect} from "react";

export default function ProgressLoading ({text, isShowing}) {
    useEffect(()=>{
        stopBodyScroll(isShowing);
    },[isShowing])

  return (
    <div className="progress-loading" style={{display: isShowing? 'flex' : 'none', backgroundImage: `url(${Background})`}} >
      <img src={TitleIcon} alt='' style={{ width: '220px', marginBottom: '20px'}}/>
      <div className='loading-content'>
        <div className='loading-icon'/>
        <div className="loading-text">{text}</div>
      </div>
    </div>
  )
}

export function stopBodyScroll(isStop) {
    let bodyEl = document.body;
    let top = window.scrollY;

    if (isStop) {
        bodyEl.style.position = "fixed";
        bodyEl.style.top = -top + "px";
    } else {
        const top = bodyEl.style.top;
        bodyEl.style.position = "";
        window.scrollTo(0, -parseInt(top));
    }
}