import "./about.scss";
import darkCircle1 from './assets/dark-circle-1.png'
import darkCircle2 from './assets/dark-circle-2.png'
import lightCircle1 from './assets/light-circle-1.png'
import lightCircle2 from './assets/light-circle-2.png'
import React from "react";
import titleIcon from './assets/title.png';
import {useNavigate} from "react-router-dom";
import OkButton from './assets/ok-button.png';

export default function About(props){
    const { title, content, style, theme } = props;
    const navigate = useNavigate();

    const clickOk = () =>{
        navigate(-1);
    }

    return(
        <div style={{position: 'relative', width: '100vw'}}>
            <div id="about-container" style={style.background}>
                <img src = {titleIcon} style={{height: '22px', position: 'absolute', left: '21px',top: '28px'}}/>
                {/*<img className="about-page-ornament1" src={`${theme==='dark'?darkCircle1:lightCircle1}`} alt=""/>*/}
                {/*<img className="about-page-ornament2" src={`${theme==='dark'?darkCircle2:lightCircle2}`} alt=""/>*/}
                {/*<ArrowIcon />*/}
                <div id='about-content' style={style.content}>
                    {/*<div id="about-title" style={style.title}>*/}
                    {/*    {title}*/}
                    {/*</div>*/}
                    <div>
                        {
                            content.map((content,index)=>{
                                return <div  key={index} className='lineBetween'>{content}</div>
                            })
                        }
                    </div>
                </div>
                <img src={OkButton} style={{width: '184px', marginTop: '54px'}} onClick={clickOk}/>
            </div>
        </div>
    )
}