import About from "./About";
import {about} from '../../config'
import {useContext} from "react";
import {ThemeCtx} from "../../App";
import background from './assets/background.png';

export default function AboutConfigPage() {
    const {title, content} = about
    const {theme} = useContext(ThemeCtx);

    return (
        <About title={title} content={content} style={styleConfig[theme.main]} theme={theme.main}/>
    )
}

const styleConfig = {
    light: {
        background: {
            backgroundImage: `url(${background})`,
        },
        title: {
            color: '#101010'
        },
        content: {
            color: '#ffffff'
        }
    },
    dark: {
        background: {
            background: '#1D1D1D'
        },
        title: {
            color: '#FFFFFF'
        },
        content: {
            color: 'rgba(255, 255, 255, 0.5)'
        }
    },
}




