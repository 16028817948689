import './home.scss'
import MenuIcon from "../../components/menuIcon/menuIcon";
import Description from "../../components/description/description"
import NftCard from '../../components/nftCard/nftCard';
import FooterWarning from '../../components/footerWarning/footerWarning';
import React, {useContext, useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {AuthContext} from "../../App";
import Background from '../../assets/by-health/background.png';
import TitleIcon from '../../assets/by-health/titleIcon.png';
import MuteIcon from '../../assets/by-health/mute.png';
import UnmuteIcon from '../../assets/by-health/unmute.png';
import OkButton from "../../components/button/button";
import HomepageIcon from './assets/homepage-icon.png';
import HomepageTitle from './assets/homepage-title.png';
import {bottomToast} from "../../utils/toast";
import {useGetAuthentication, usePostAuthentication} from "../../utils/useAuth";
import {stopBodyScroll} from "../../components/progressLoading/ProgressLoading";
import AuthModalConfig from "../claimPage/components/ModalConfig";

export default function Home(props) {
    const {theme, style, nftInfo, mute, setMute} = props
    const [currentIndex, setIndex] = useState(0);
    const {state} = useContext(AuthContext);
    const navigate = useNavigate();
    const auth = localStorage.getItem('authenticated');
    const [modal, setModal] = useState(false);

    const handleSlideChange = (e) => {
        let index = e.realIndex;
        setIndex(index);
    }

    const claim = () => {
        if (state.isLogin) {
            if(auth === '1'){
                navigate('/claim')
            }else{
                setModal(true);
            }
        } else {
            navigate('/login');
        }
    }

    const accessToken = localStorage.getItem('accessToken');
    const getAuth = useGetAuthentication(accessToken);

    const getAuthInformation = async() => {
        return await getAuth();
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    useEffect(() => {
        if (accessToken) {
            getAuthInformation().then((res)=>{
                if(res){
                    localStorage.setItem('phone', res.phone);
                    localStorage.setItem('authenticated', res.authenticated);
                }
            });
        }
    },[accessToken])

    return (
        <div className={`home-page ${theme === 'dark' ? 'home-page-dark-theme' : ''}`}
             style={{backgroundImage: `url(${Background})`}}>
            <MenuIcon/>
            <AuthModalConfig visible={modal} setVisible={setModal}/>
            <img src={TitleIcon} alt='' style={{width: '164px', marginTop: '28px'}}/>
            <img src={mute ? MuteIcon:UnmuteIcon} alt='' onClick={()=>setMute(!mute)} id='musicIcon'
                 style={{height: '27px', position: 'absolute', top: '23px', right: '15px', zIndex: '99', pointerEvents: 'none', opacity: 1}}
            />
            {/*<img className="top-background-img" src={nftInfo[currentIndex].image} alt=""/>*/}
            {/*<div className={`top-container ${theme==='dark'?'top-container-dark-theme':''}`}>*/}
            {/*  <div className={`title ${theme==='dark'?'title-dark-theme':''}`}>{nftInfo[currentIndex].header}</div>*/}
            {/*  <NftCard nftInfo={nftInfo} handleSlideChange={handleSlideChange}/>*/}
            {/*</div>*/}
            {/*<Description desc={nftInfo[currentIndex].desc}/>*/}
            {/*<button onClick={claim} className='retrieve-button' style={{background:style.buttonBackground}}>立即领取</button>*/}
            <img src={HomepageTitle} style={{width: '324px', marginTop: '40px'}}/>
            <img src={HomepageIcon} style={{width: '250px', position: "absolute",top: '80px'}}/>
            <div className='home-page-desc-container'>
                <div>汤臣倍健透明工厂10周年</div>
                <div>特别打造3000份数字藏品</div>
                <div>诚邀您见证，更有机会获得定制款足金摆件</div>
                <div style={{fontWeight: 400}}>（详见左上角活动说明）</div>
            </div>
            <OkButton Click={claim}/>
            <FooterWarning/>
        </div>
    )
}