import {Toast} from "antd-mobile";

const bottomToast = (content, position, callBack) => {
    Toast.show({
        content: content,
        position: position ? position: 'center',
        onClose: callBack ?  callBack() : null,
    })
}

export {bottomToast}