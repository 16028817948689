import './button.scss';
import ButtonContainer from './assets/button.png';

export default function OkButton(props){
    const { Click, text, style } = props;

    return(
        <div className='ok-button-container' onClick={Click} style={style}>
            <img src={ButtonContainer} style={{width:'100%'}}/>
            <div className='ok-button-content-text'>{text || '立即领取'}</div>
        </div>
    )
}