import "./claim.scss"
import React, {useContext, useState, useEffect} from "react";
import {AuthContext, serverAddress} from "../../App";
import {responseCode} from "../../config";
import {useNavigate} from "react-router";
import {bottomToast} from "../../utils/toast";
import ArrowIcon from "../../components/arrowIcon/arrowIcon";
import FooterWarning from '../../components/footerWarning/footerWarning';
import ProgressLoading from '../../components/progressLoading/ProgressLoading';
import BlueQuestionMark from './assets/purple.png'
import AuthModalConfig from "./components/ModalConfig";
import Background from '../../assets/by-health/background.png';
import OkButton from "../../components/button/button";
import MuteIcon from '../../assets/by-health/mute.png';
import UnmuteIcon from '../../assets/by-health/unmute.png';
import TitleIcon from '../../assets/by-health/titleIcon.png';
import BackIcon from '../../assets/by-health/backIcon.png';
import Frame from './assets/farme.png';

export default function Claim(props) {
    const {style, theme, mute, setMute} = props;
    const [code, setCode] = useState();
    const [modal, setModal] = useState(false);

    const {state} = useContext(AuthContext);
    const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    const handleClaim = () => {
        const auth = localStorage.getItem('authenticated');
        if (auth === '0') {
            setModal(true);
        } else if (!code) {
            bottomToast('请输入领取码');
        } else if (!state.isLogin) {
            navigate("/login");
            bottomToast('请先登录');
        } else {
            try {
                fetch(
                    serverAddress + "/nfts/redemption",
                    {
                        method: "POST",
                        headers: {"accessToken": state.accessToken},
                        body: JSON.stringify({"collectionId": 6, "code": code})
                    }
                ).then((r) => {
                    r.json().then((result) => {
                        if (result.returnCode === responseCode.success) {
                            const nft = result.data.nfts;
                            let {name, tokenId, thumbnailURL, collectionInfo, displayURL} = nft[0];
                            localStorage.setItem('desc', collectionInfo.description);
                            localStorage.setItem('name', name);
                            navigate("/congrats?tokenId=" + tokenId + "&url=" + thumbnailURL);
                        } else if (result.returnCode === responseCode.redemptionCodeInvalid) {
                            bottomToast("请输入正确的领取码");
                        } else if (result.returnCode === responseCode.redemptionCodeAlreadyUsed) {
                            bottomToast("该兑换码已使用");
                        } else if (result.returnCode === responseCode.claimAmountExceeded) {
                            bottomToast("领取数量超过限制");
                        } else if (result.returnCode === "24000") {
                            bottomToast("请输入正确的领取码");
                        } else {
                            bottomToast(result.msg)
                        }
                    })
                });
            } catch (error) {
                console.log("error", error);
            }
        }
    }

    return (
        <div className='claim-page' style={{backgroundImage: `url(${Background})`}}>
            {/* <ProgressLoading text={'努力铸造中...'} isShowing={loading}/> */}
            <AuthModalConfig visible={modal} setVisible={setModal}/>
            <img src={BackIcon} alt='' style={{height: '14px',width:'9px', position: 'absolute', top: '25px', left: '21px'}} onClick={()=> navigate('/')}/>
            <img src={TitleIcon} alt='' style={{width: '164px', marginTop: '20px'}}/>
            <img src={mute ? MuteIcon : UnmuteIcon} alt=''
                 style={{height: '27px', position: 'absolute', top: '15px', right: '15px'}}
                 onClick={() => setMute(!mute)}/>
            {/*<ArrowIcon/>*/}
            {/*<div className={`card-container ${theme.main==='dark'?'card-container-dark-theme':''}`}>*/}
            {/*    <img className='question-mark' src={BlueQuestionMark} alt=""/>*/}
            {/*</div>*/}
            <img src={Frame} alt='' style={{width: '318px', marginTop: '38px'}}/>
            <div className='input-container'>
                <input className={`input-code ${theme.main === 'dark' ? 'input-code-dark-theme' : ''}`} size="1"
                       onChange={(e) => {
                           setCode(e.target.value)
                       }}
                       placeholder="输入兑换码"/>
            </div>
            <OkButton Click={handleClaim}/>
            <FooterWarning/>
        </div>
    )
}