import Policy from './Policy'
import {useContext} from "react";
import {ThemeCtx} from "../../App";
import {policy} from "../../config"

export default function PolicyConfigPage () {
  const { theme } = useContext(ThemeCtx);
  const {title, content} = policy

  const config = {
    title,
    content,
    theme
  }

  return (
    <Policy config={config}/>
  )
}
