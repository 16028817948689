import './Policy.scss'
import {useEffect} from "react";
import blackCloseIcon from './assets/black-close-icon.png'
import whiteCloseIcon from './assets/white-close-icon.png'
import {useNavigate} from "react-router-dom";

export default function Policy(props) {
  const {title, content, theme} = props.config

  const navigate = useNavigate();

  const clickClose = () => {
    navigate(-1)
  }

  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])

  return (
    <div className={`policy-page ${theme.main==='dark'?'policy-dark-theme':'policy-light-theme'}`}>
      <div className={`top-container ${theme.main==='dark'?'dark-theme-border':'light-theme-border'}`}>
        <img className='back-icon' onClick={clickClose} src={`${theme.main==='dark'?whiteCloseIcon:blackCloseIcon}`} alt=''/>
        <div className="title">{title}</div>
      </div>
      <div className="content">
        {content}
      </div>
    </div>
  )
}