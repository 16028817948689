import {useNavigate} from "react-router";
import "./congrats.scss";
import React, {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import FooterWarning from '../../components/footerWarning/footerWarning';
import Description from "../../components/description/description"
import darkThemeLight from './assets/darktheme-light.png'
import lightThemeLight from './assets/lighttheme-light.png'
import ProgressLoading from '../../components/progressLoading/ProgressLoading';
import OkButton from "../../components/button/button";
import Background from '../../assets/by-health/background.png';
import MenuIcon from "../../components/menuIcon/menuIcon";
import TitleIcon from "../../assets/by-health/titleIcon.png";
import UnmuteIcon from "../../assets/by-health/unmute.png";
import MuteIcon from "../../assets/by-health/mute.png";
import LeftIcon from './assets/left-icon.png';
import RightIcon from './assets/right-icon.png';
import SmallRectangle from './assets/small-rectangle.png';
import Frame from './assets/frame.png';

export default function Congrats(props) {
    const {style, theme, mute, setMute} = props;
    let navigate = useNavigate();
    let [params] = useSearchParams();

    const confirm = () => {
        navigate('/list');
    }

    const url = params.get("url");
    const name = localStorage.getItem("name")
    const desc = localStorage.getItem("desc")
    const tokenId = params.get("tokenId")

    const [loaded, setLoaded] = useState(false);
    const [img1Loaded, setImg1Loaded] = useState(false);
    const [img2Loaded, setImg2Loaded] = useState(false);

    useEffect(() => {
        if (img1Loaded && img2Loaded) {
            setTimeout(() => {
                setLoaded(true);
            }, 2000)
        }
    }, [img1Loaded, img2Loaded]);

    function getUrl(url) {
        return `${url}!small`
    }

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [])

    return (
        <div className='congrats-page' style={{backgroundImage: `url(${Background})`}}>
            <ProgressLoading text={'努力铸造中...'} isShowing={loaded}/>
            <MenuIcon/>
            <img src={TitleIcon} alt='' style={{width: '164px', marginTop: '28px'}}/>
            <img src={mute ? MuteIcon : UnmuteIcon} alt=''
                 style={{height: '27px', position: 'absolute', top: '23px', right: '15px'}}
                 onClick={() => setMute(!mute)}/>
            <div className='congrats-page-title-container'>
                <img src={LeftIcon} alt='' style={{width: '27px', marginRight: '10px'}}/>
                <div style={{minWidth: '170px'}}>{name}</div>
                <img src={RightIcon} alt='' style={{width: '27px', marginLeft: '10px'}}/>
            </div>
            <div style={{position: 'relative'}}>
                <img src={SmallRectangle} alt='' style={{width: '132px', height: '24px', marginTop: '12px'}}/>
                <div className='congrats-page-subtitle-container'>
                    #{tokenId}/3000
                </div>
            </div>
            <div style={{position: 'relative'}}>
                <img src={Frame} alt='' style={{height: '397px', marginTop: '10px'}}/>
                <div className='congrats-page-inline-img-container'>
                    <img src={getUrl(url)} style={{width: '100%'}}/>
                </div>
            </div>

            <div className='congrats-page-desc-container'>
                {getDesc(name).map((item, index) => {
                    return (
                        <div key={index}>{item}</div>
                    )
                })}
            </div>

            {/*<img className="nft-background-image" src={getUrl(url)} alt="" onLoad={()=>{setImg1Loaded(true)}} mode="widthFix"/>*/}
            {/*<div className={`nft-container ${theme==='dark'?'nft-container-dark-theme':''}`}>*/}
            {/*    <img className="nft-background-light" mode="heightFix" src={`${theme==='dark'?darkThemeLight:lightThemeLight}`} alt=""/>*/}
            {/*    <div className={`nft-title ${theme==='dark'?'nft-title-dark-theme':''}`}>{name}</div>*/}
            {/*    <div className={`nft-id ${theme==='dark'?'nft-id-dark-theme':''}`}># {tokenId}</div>*/}
            {/*    <img className="nft-image" src={getUrl(url)} alt="" onLoad={()=>{setImg2Loaded(true)}}/>*/}
            {/*</div>*/}
            {/*<Description desc={desc}/>*/}
            <OkButton Click={confirm} text='确定'/>
            <FooterWarning style={{width: '340px', marginTop: '19px'}}/>
        </div>
    )
}

export function getNumber(name) {
    switch (name) {
        case '一诺千金「诚信印」常规款':
            return 1430;
        case '一诺千金「诚信印」足金款':
            return 9;
        case '「经典双蛋白」常规款':
            return 1545;
        case '「经典双蛋白」足金款':
            return 16;
        default:
            return undefined;
    }
}

export function getDesc(name) {
    switch (name) {
        case '一诺千金「诚信印」常规款':
            return ['一诺千金「诚信印」常规款 数字藏品', '“诚信比聪明更重要”'];
        case '一诺千金「诚信印」足金款':
            return ['一诺千金「诚信印」足金款 数字藏品', '请联系小程序客服兑换“实物足金摆件”'];
        case '「经典双蛋白」常规款':
            return ['「经典双蛋白」常规款 数字藏品', '科学营养 用心而为'];
        case '「经典双蛋白」足金款':
            return ['「经典双蛋白」足金款 数字藏品', '请联系小程序客服兑换“实物足金摆件”'];
        default:
            return undefined;
    }
}