import loginWhiteLogo from "./assets/login-white-logo.png";
import loginBlackLogo from "./assets/login-logo-black.png";
import nft1 from './assets/nft/1.gif';
import nft2 from './assets/nft/2.gif';
import nft3 from './assets/nft/3.jpg';
import nft4 from './assets/nft/4.jpg';
import nft5 from './assets/nft/5.png';
import ondine from './assets/nft/little-ondine-nft-image.jpg';
import dma from './assets/nft/dma-nft-image.jpg';
import defaultAvatar from './assets/default-avatar.png'
import ondineAvatar from './assets/avatar.svg'
import loginBackgroundImage from './assets/login-background-image.png';


// 配置theme (dark or light)
export const mainTheme = 'light'

// 配置颜色 16进制格式 如#FD5D5D
export const colorTheme = '#5F9CDA'

// 配置登录页的logo，分深色主题logo和浅色主题logo
export const loginLogo = {
	dark: loginBlackLogo,
	light: loginWhiteLogo
}

// 配置用户协议文本，需注意content换行开头不留空格
export const policy = {
	title: '《用户使用协议》',
	content: `活动名称：[汤臣倍健透明工厂10周年-限定数字藏品]申领活动

数字藏品的领取时间：2022年9月23日-2022年11月30日

1．您（指拥有汤臣倍健透明工厂10周年数字藏品NFT领取码的用户）使用领取码，并在领取码有效期内通过数字藏品发行站点完成账户登录、验证并获得对应的数字藏品，该数字藏品限量3000份。每个领取码有效期至【2022年11月30日】且仅可获得一次数字藏品领取机会，领取后即失效。
2．在您登录本站点时，您需注册咖菲科技网站账户，此时我们会收集您的手机号，以便帮助您完成账号注册。在您领取本数字藏品之前，为满足法律法规的实名制要求，我们会收集您的姓名、身份证号、手机号，以帮助您完成身份验证。如果您不授予前述同意，您将无法领取数字藏品，并可能无法正常使用本站点的服务。上海咖菲信息科技有限公司（以下简称“我们”）将严格遵守相关法律法规与监管要求，为履行本协议约定之目的，依法使用、存储您的信息，并对您的信息保密。
3．在您注册或登录账户以及完成实名认证后，您还需要提供领取码，作为领取数字藏品的凭证。特别提示：您的手机号、身份证号及领取码请务必自行妥善保管。因个人原因，如泄露、遗失、转号等，而导致数字藏品遗失、或被盗、或冒领等后果及损失由您自行承担。
4．如发现用户恶意利用多个手机号、身份证号或者其他不正当行为获取数字藏品，该用户的领取资格及数字藏品对应的相关权益将会被取消，所引发的一切法律责任和损失由该用户承担。
5．每一份数字藏品都是非卖品。数字藏品仅可用于您的个人收藏，学习，研究，欣赏和展示之目的，不可转赠、不可售卖或以其它任何形式进行转让，亦不可用于任何商业行为，并且严格禁止炒作、场外交易、欺诈等任何非法用途，由此所产生违法及违约责任由用户自行承担。因本次活动中的数字藏品都是非卖品，故不允许用户因个人原因申请退换。
6．您在本站点领取数字藏品后，可通过汤臣倍健营养家及汤星球微信小程序对应的数字藏品活动页面，查看所持有的数字藏品。
7．您不得对所持有的数字藏品进行转赠、售卖或以其它任何形式进行转让。 在领取后需妥善保管自己的数字藏品和自己的账户、区块链钱包私钥。领取后发生的一切遗失、失窃、无法找回等情形，均由您自行承担。您领取数字藏品后，利用该数字藏品实施的违约、违法等任何行为，由您个人自行承担一切责任。
8．您确认，一旦您以在线点击确认或其他方式签署本协议，即视为您已阅读并同意本协议的所有条款，且知晓并理解本协议所有条款的含义及相应的法律后果，本协议即视为已于您在线点击确认或以其他方式签署本协议之日起有效签署，对各方成立并生效。本次活动数字藏品的领取对象为具有完全民事行为能力的公民。如您在本站点领取数字藏品，即表示您已经知晓并符合了该领取标准。若您因未符合承担民事责任的能力而导致发生的任何违反法律法规的行为，我们不承担任何法律责任，同时本站点保留取消您领取资格的权利。
9．基于区块链技术的特性，数字藏品具有唯一、不可篡改的特点，数字藏品中的数字内容，铸造时间等信息无法篡改。在您获得数字藏品后，您与数字藏品的所有权等权利关系将通过智能合约记录在区块数据中。
10．您理解并同意，您领取数字藏品后，只拥有该数字藏品本身的使用权，不拥有该数字藏品对应的原艺术品的所有权及包括著作权在内的各项知识产权，亦不拥有该数字藏品的知识产权。
11．您理解并同意，基于维护网络秩序和安全，如我们发现您存在违法违规或违反本协议约定情形的，我们有权采取同时采取一项或多项措施，包括但不限于： A) 要求您限期纠正违规或违约行为； B) 屏蔽或限制您使用数字藏品； C) 对违法违规信息进行删除或屏蔽，取消您的领取资格或数字藏品对应的相关权益 ；D) 停止或终止向您提供部分或全部服务； E) 其他合理、合法的措施。 我们因任何原因未采取上述任何措施，不代表我们放弃未来采取该等措施的权利。如您的行为使我们遭受损失的，我们还有权要求您赔偿我们的全部损失，包括商誉损失、赔偿金、律师费、诉讼费等。
12．您理解并同意，在您获得数字藏品和使用本服务的过程中，可能会遇到不可抗力等风险因素。如出现下列任一情况致使我们无法履行本协议约定的，我们将努力在第一时间与相关单位配合，及时进行修复，但是由此给您或第三方造成的损失，我们不承担责任： A) 在与本服务相关的系统停机维护或升级期间； B) 因自然灾害如洪水、地震、瘟疫流行和风暴等以及社会事件如战争、动乱、法律法规政策变化和政府行为等不可抗力原因； C) 您操作不当或通过非我们授权或认可的方式使用本服务； D) 因病毒、木马、恶意程序攻击网络拥堵、系统不稳定、系统或设备故障、通讯故障、电力故障、电信设备故障、第三方服务瑕疵等原因。
13．您理解并同意，鉴于网络服务的特殊性，我们对本服务的持续提供可能受到多种因素的影响，如出现技术升级、服务体系升级、或因经营策略调整或配合国家重大技术、法规政策等变化。我们可能随时终止、中断提供服务或变更所提供服务的形式、规格或其他方面，但我们会尽快通知您前述情况，而您不会因此追究我们的责任。
14．除上文已经特别指出的以外，站点上的所有内容，包括但不限于文字、图片、档案、资讯、资料、平台架构、平台画面的安排、网页设计等，均由我们依法拥有其知识产权，包括但不限于商标权、专利权、著作权、商业秘密等。非经我们书面同意，您不得擅自使用、修改、反向编译，复制，公开传播、改变、散布、发行或公开发表、转让、许可、商业利用本站点的相关软件、内容以及我们享有的知识产权。否则我们有权立即终止本协议并要求您停止违约和/或侵权行为，您应对您的违约或侵权行为给我们或其他权利人造成的损失承担相应赔偿责任。
15．为进一步改善用户体验，我们将联合我们的关联方和/或合作方持续更新服务，包括但不限于版本升级、功能升级、技术升级、新增、更新或调整服务内容和功能，本协议可能会随之更新。我们也会根据国家法律法规的变化要求，更新本协议的相关条款。更新的内容将于协议更新公告公布的生效之日起生效。若您不接受变更后的服务内容或功能，或不同意更新后的协议内容，您应立即停止使用相关服务。若您继续使用本协议相关服务的，即视为您已同意我们作出的变更，并自愿接受变更后的协议内容约束。
16．我们将严格按照《中华人民共和国个人信息保护法》等相关法律法规和/或取得您的授权同意下处理、保护您在使用我们服务时所提供的个人信息，如您对我们收集使用您的个人信息以及您的相关权益有疑问，可通过 “cocafe咖菲科技”微信公众号后台消息联系我们。
17．在法律允许的范围内，以上说明的最终解释权归上海咖菲信息科技有限公司所有。上海咖菲信息科技有限公司保留任何对针对用户使用本站点服务细则的修改，更新和迭代的权利。具体请见本站点的公示。
`,
}

// 配置关于页文本信息
export const about = {
	title: '【活动规则】',
	content: [
		'【活动规则】',
		'[汤臣倍健透明工厂10周年-限定数字藏品]申领活动',
		'一、兑换时间：2022年9月23日-11月30日',
		'二、兑换规则：',
		'前往“汤臣倍健营养家微信号及小程序、汤臣倍健汤星球微信号及小程序、汤臣倍健及其子品牌的京东自营店”参与数字藏品活动，对于符合条件的参与者将会以短信或企业微信等方式发放“唯一兑换码”，凭借该兑换码，可进入汤臣倍健营养家或汤臣倍健汤星球公众号 [汤臣倍健透明工厂10周年-限定数字藏品]入口进行领取。',
		'三、申领奖品明细',
		'1.一诺千金「诚信印」常规款 数字藏品。',
		'2.一诺千金「诚信印」足金款 数字藏品。可兑换实物一诺千金「诚信印」足金摆件。（纯度：Au990；每件最低：15g）',
		'3.「经典双蛋白」常规款 数字藏品。',
		'4.「经典双蛋白」足金款 数字藏品。可兑换实物「经典双蛋白」足金摆件。（纯度：Au990；每件最低：15g）',
		'备注：足金摆件设计样式、规格、每件克重以实际收到的为准。。',
		'四、一诺千金「诚信印」常规款/足金款或「经典双蛋白」常规款/足金款数字藏品兑换说明：',
		'请于2022年9月23日-11月30日期间登录【汤臣倍健透明工厂10周年限定数字藏品】活动领取H5页面（即汤臣倍健营养家公众号中公示“中奖兑换”页面），输入中奖兑换码领取虚拟数字藏品。领取虚拟数字藏品需凭手机号及验证码登录，点击同意隐私政策及用户协议，登录成功后-输入兑换码-点击立即领取-查看获得的数字藏品-点击生成海报-即可将数字藏品以图片形式保存至用户手机中。逾期未兑换视为放弃。2022年12月31日前，均可在H5 “我的藏品”位置查看已兑换的数字藏品信息。',
		'五、实物足金摆件领取说明：',
		'已兑换领取一诺千金「诚信印」足金款或「经典双蛋白」足金款数字藏品的用户，保存领取数字藏品海报（含对应的唯一合约地址编号），在14天内会有客服电话联系，用户在接到客服电话的7天内，按照客服指引，凭数字藏品海报（含对应的唯一合约地址编号）、真实姓名、真实手机号进行核销，并填写收件信息。逾期或收件信息填写错误则视为自动放弃实物足金摆件。核销成功且在客服收到准确邮寄信息后，实物足金摆件将于90天内，以包邮的方式寄送给用户。',
		'【关于藏品】',
		'数字藏品创意作品具有作品的唯一性、不可分割性以及不可被篡改的特点，此商品具有特殊性，因此不支持退换，谢谢您的理解与配合！',
		'本数字藏品为纪念汤臣倍健透明工厂10周年而创作的虚拟藏品。品牌标志性元素「诚信印」、「经典双蛋白」以数字藏品形象诞生于“未来幻境”之中。象征着品牌对透明、坦诚价值观的坚守，见证着汤臣倍健透明工厂10年的用心历程。',
		'上述数字藏品创意作品的知识产权由汤臣倍健股份有限公司拥有，数字藏品的所有权人或者合法拥有者不得将上述数字藏品创意作品用于任何商业途径以及任何非法途径。',
		'本网站仅代为展示和提取数字藏品创意作品，严格禁止自然人、法人或非法人组织利用数字藏品创意作品从事炒作、场外交易、欺诈等非法行为或以任何非法方式使用。'
	]
}

// 配置首页nft信息
export const homePageNFT = [
	{image: dma, header: 'DMA 元宇宙登陆盾', desc: 'DMA元宇宙登陆盾是AICPA & CIMA 国际注册专业会计师公会首个对外发行的数字藏品，纪念该项目登陆中国并见证秋季首考', avatar: ondineAvatar, author:'AICPA & CIMA x 咖菲科技'}
]


export const responseCode = {
  success                             : "20000",
	redisLockFailedToGet                : "20016",
	requestIdAlreadyReceived            : "20017",
	userNFTRecordNotFound               : "20020",
	// 通用 GENERAL: 20---\
	unknownFailure                      : "30000",
	invalidParam                        : "20001",
	cannotAccessToThisResource          : "20002",
	requestHeaderParamIsEmpty           : "20006",
	notifyOpenidError                   : "20007",
	requestUnmarshalError               : "20009",
	sendMessageError                    : "20012",
	callPortalError                     : "20013",
	databaseError                       : "20014",
	blockchainError                     : "20015",
	marshalError                        : "20021",
	recordNotFound                      : "20022",

	// 鉴权相关 AUTH: 21---
	errorAuthCheckTokenFail             : "20003",
	errorAuthCheckTokenTimeout          : "20004",
	errorAuthToken                      : "20005",

	// 用户相关 USER: 22---
	invalidUser                         : "20011",
	userNotAuth                         : "20010",
	userNotFound                        : "23000",
	invalidAccessTokenDueToRemoteLogin  : "23001",

	// APP相关: 23---
	appIdError                          : "20008",

	// 兑换码: 24---
	redemptionCodeInvalid               : "20018",
	redemptionCodeAlreadyUsed           : "20019",
	claimAmountExceeded                 : "25000"
}
